import { css } from '@emotion/react';
import React from 'react';
import { FeedType } from '../../../../models/feedType';
import { ImageFileData } from '../../../../models/image';
import { useFixedContainer } from '../../../FixedContainer/hook';
import Layout from '../../../Layout';
import NavigationBar from '../../../NavigationBar';
import {
  FeedDetailImageUpload,
  imageUploadContainerDefaultPadding,
} from '../components/ImageUpload';
import { FeedDetailUserHeader } from '../components/UserHeader';
import { feedDetailSectionStyle, feedDetailTextAreaStyle } from '../style';
import useSWR, { mutate } from 'swr';
import useSWRMutation from 'swr/mutation';
import { useCoreApi } from '../../../../apis/core';
import { swrDefaultOption } from '../../../../utils/swr';
import { goPreviousPage } from '../../../../utils/route';
import { useLocation } from '@reach/router';
import Modal from '../../../Modal/Modal';
import ModalCheck from '../../../Modal/ModalCheck';

interface FeedDetailEditPageProps {
  accessTokenRef: React.MutableRefObject<string | undefined>;
  channelId: string;
  feedId: string;
  feedType: FeedType;
}

export default function FeedDetailEditPage({
  accessTokenRef,
  channelId,
  feedId,
  feedType,
}: FeedDetailEditPageProps) {
  const location = useLocation();
  const coreApi = useCoreApi();

  const [text, setText] = React.useState<string>('');
  const [images, setImages] = React.useState<ImageFileData[]>([]);

  const [isOpenAlertModal, setIsOpenAlertModal] = React.useState(false);
  const [alertModalText, setAlertModalText] = React.useState('');

  const fixedEl = React.useRef<HTMLDivElement | null>(null);
  const textAreaEl = React.useRef<HTMLTextAreaElement | null>(null);

  const handleChangeText = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setText(e.target.value);
    },
    []
  );

  async function sendGetMeRequest() {
    return coreApi.getMe({
      accessToken: accessTokenRef.current || '',
    });
  }

  const handleFocusTextArea = React.useCallback(() => {
    if (!fixedEl.current) return;
    fixedEl.current.style.paddingBottom = `${imageUploadContainerDefaultPadding}px`;
  }, []);

  const handleBlurTextArea = React.useCallback(() => {
    if (!fixedEl.current) return;
    fixedEl.current.style.paddingBottom = `calc(${imageUploadContainerDefaultPadding}px + env(safe-area-inset-bottom))`;
  }, []);

  const { data: getMeData, error: getMeError } = useSWR(
    ['getMe', channelId],
    sendGetMeRequest,
    swrDefaultOption
  );

  React.useEffect(() => {
    console.error(getMeError);
  }, [getMeError]);

  async function sendGetCommunityCreatorFeedsByFeedIdRequest() {
    return coreApi.getCommunityCreatorFeedByFeedId({
      accessToken: accessTokenRef.current || '',
      channelId,
      feedId,
    });
  }

  async function sendGetCommunityUserFeedsByFeedIdRequest() {
    return coreApi.getCommunityUserFeedByFeedId({
      accessToken: accessTokenRef.current || '',
      channelId,
      feedId,
    });
  }

  const {
    data: getCommunityFeedsByFeedIdData,
    error: getCommunityFeedsByFeedIdError,
    isLoading: isLoadingCommunityFeedsByFeedId,
    mutate: mutateGetCommunityFeedsByFeedId,
  } = useSWR(
    `getCommunityFeedsByFeedId`,
    feedType === FeedType.CREATOR
      ? sendGetCommunityCreatorFeedsByFeedIdRequest
      : sendGetCommunityUserFeedsByFeedIdRequest,
    swrDefaultOption
  );

  // route 변경시 refetch
  React.useEffect(() => {
    setText('');
    setImages([]);
    if (!isLoadingCommunityFeedsByFeedId) mutateGetCommunityFeedsByFeedId();
  }, [location]);

  React.useEffect(() => {
    if (!getCommunityFeedsByFeedIdData) return;

    setText(getCommunityFeedsByFeedIdData.content);
    setImages(getCommunityFeedsByFeedIdData.images);
  }, [getCommunityFeedsByFeedIdData]);

  React.useEffect(() => {
    console.error(getCommunityFeedsByFeedIdError);
  }, [getCommunityFeedsByFeedIdError]);

  async function sendPatchCommunityCreatorFeedsByFeedIdRequest(
    key: string[],
    { arg }: { arg: { content: string; images: ImageFileData[] } }
  ) {
    return coreApi.patchCommunityCreatorFeedsByFeedId({
      accessToken: accessTokenRef.current || '',
      channelId,
      feedId,
      content: arg.content,
      images: arg.images,
    });
  }

  async function sendPatchCommunityUserFeedsByFeedIdRequest(
    key: string[],
    { arg }: { arg: { content: string; images: ImageFileData[] } }
  ) {
    return coreApi.patchCommunityUserFeedsByFeedId({
      accessToken: accessTokenRef.current || '',
      channelId,
      feedId,
      content: arg.content,
      images: arg.images,
    });
  }

  const {
    trigger: triggerPatchCommunityFeedsByFeedId,
    isMutating: isMutatingPatchCommunityFeedsByFeedId,
    error: patchCommunityFeedsByFeedIdError,
  } = useSWRMutation(
    ['patchCommunityFeedsByFeedId', channelId],
    feedType === FeedType.CREATOR
      ? sendPatchCommunityCreatorFeedsByFeedIdRequest
      : sendPatchCommunityUserFeedsByFeedIdRequest
  );

  const handleCloseAlertModalHandler = React.useCallback(() => {
    setIsOpenAlertModal(false);
    setAlertModalText('');
    if (!patchCommunityFeedsByFeedIdError) {
      // 성공했다면 이전 페이지로 보내기
      goPreviousPage(location);
    }
  }, [location, patchCommunityFeedsByFeedIdError]);

  const openAlertModalHandler = React.useCallback((text: string) => {
    setIsOpenAlertModal(true);
    setAlertModalText(text);
  }, []);

  const isEnableClickSave = React.useMemo(
    () => !isMutatingPatchCommunityFeedsByFeedId && text !== '',
    [isMutatingPatchCommunityFeedsByFeedId, text]
  );

  const handleClickSave = React.useCallback(() => {
    if (!text) return;

    try {
      triggerPatchCommunityFeedsByFeedId({
        content: text,
        images,
      });
      openAlertModalHandler('글 수정에 성공하였습니다!');
      mutate(['mutateContentSectionFeeds', channelId, feedType]);
    } catch (err) {
      console.error(err);
    }
  }, [
    channelId,
    feedType,
    images,
    openAlertModalHandler,
    text,
    triggerPatchCommunityFeedsByFeedId,
  ]);

  return (
    <Layout>
      <NavigationBar
        rightButton={
          <div
            css={[
              saveButtonStyle,
              isEnableClickSave ? undefined : saveButtonDisabledStyle,
            ]}
            onClick={handleClickSave}
          >
            저장
          </div>
        }
      >
        {feedType === FeedType.CREATOR && '피드'}
        {feedType === FeedType.USER && '커뮤니티'} 게시물 수정
      </NavigationBar>
      <section css={feedDetailSectionStyle}>
        <FeedDetailUserHeader
          name={getMeData?.name || ''}
          profileImageUrl={getMeData?.profileImage}
        />
        <textarea
          ref={textAreaEl}
          value={text}
          onChange={handleChangeText}
          css={feedDetailTextAreaStyle}
          onFocus={handleFocusTextArea}
          onBlur={handleBlurTextArea}
        />
        <FeedDetailImageUpload
          ref={fixedEl}
          images={images}
          onChangeImages={setImages}
          channelId={channelId}
          accessTokenRef={accessTokenRef}
          css={imageUploadStyle}
        />
      </section>
      {isOpenAlertModal && (
        <Modal closeModal={handleCloseAlertModalHandler}>
          <ModalCheck
            closeModal={handleCloseAlertModalHandler}
            content={alertModalText}
          />
        </Modal>
      )}
    </Layout>
  );
}

const saveButtonStyle = css`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #c869ff;
`;

const saveButtonDisabledStyle = css`
  color: #cccccc;
`;

const imageUploadStyle = css`
  padding-bottom: calc(
    ${imageUploadContainerDefaultPadding}px + env(safe-area-inset-bottom)
  );
`;
